import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Header from "@components/shared/header";
import MainBanner from "@components/copiercosts/mainBanner";
import MainImage from "@components/copiercosts/mainImage";
import colors from "@styles/variables/copiercostsVariables.module.scss";
import CopierCostsLogo from "../logo";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
export default function TopSection(): ReactElement {
    const router = useRouter();

    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <div className={`${styles["topSection"]}`}>
            {isFormDisplayed ? (
                <div className={` ${styles["mxWrapper"]}`}>
                    <Header
                        slogan="Top Digital Copier Companies"
                        textColor={colors.offerCallNumber}
                        backGroundColor="transparent"
                    >
                        <CopierCostsLogo />
                    </Header>

                    <main className={`px-4vw lg:px-4`}>
                        <div
                            className={`flex flex-col md:flex-row items-center justify-between`}
                        >
                            <MainBanner />
                            <MainImage />
                        </div>
                    </main>
                </div>
            ) : (
                <div>
                    <Header
                        slogan="Top Digital Copier Companies"
                        textColor={colors.offerCallNumber}
                        backGroundColor="transparent"
                    >
                        <CopierCostsLogo />
                    </Header>

                    <TextBlockOffer />
                </div>
            )}
        </div>
    );
}
