import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";
import CopierCostsWrapper from "@components/layouts/copierCostsWrapper";
import TopSection from "@components/copiercosts/topSection";
import OffersList from "@components/shared/offersList";

const Benefits = dynamic(() => import("@components/copiercosts/benefits"));

const DoYouKnow = dynamic(() => import("@components/copiercosts/doYouKnow"));

export default function CopierCostsHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <CopierCostsWrapper>
            <TopSection />
            <div className={`px-4vw lg:px-4 ${styles["mxWrapper"]}`}>
                <section>
                    <OffersList offers={domain?.defaultOffers} />
                </section>
                <Benefits />
                <DoYouKnow />
            </div>
        </CopierCostsWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
