import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function MainImage(): ReactElement {
    return (
        <div className={styles["mainBannerWrapper"]}>
            <Image
                src="/assets/images/bg.svg"
                className={styles["background-img"]}
                alt="Digital Copier"
                layout="intrinsic"
                priority
                width={875}
                height={632}
            />
        </div>
    );
}
